import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Text } from "../components/Core"

function TermsOfUsePage(props) {
  let page = props.data.allUniquePagesJson.edges[0].node

  return (
    <Layout navSpacer language="en" path={props.path}>
      <SEO title={page.metaTitle} description={page.metaDescription} />

      <Section className="joshua-tree-content privacy-policy-page">
        <Container>
          <Text className="mb-1" as="h1" text={page.heading} />
          <Text text={page.body} />
        </Container>
      </Section>
    </Layout>
  )
}

export default TermsOfUsePage

export const pageQuery = graphql`
  query termsQuery {
    allUniquePagesJson(filter: { title: { eq: "/terms-of-use/" } }) {
      edges {
        node {
          heading
          metaDescription
          metaTitle
          name
          body
          title
        }
      }
    }
  }
`
